.header-minicart {
    padding: 0 10px;
    display: inline-block;
    position: relative;

    @media (min-width: 640px) {
        width: 100%;
        min-width: 250px;
        padding: 10px 0;
    }
    .button {
        margin-top: 10px;
        text-decoration: none;

        &.cart-button {
            padding: 2px 10px 2px 10px;
            border-radius: 3px 0 0 3px;
            border-right: 1px solid #CC9999;
            i {
                font-size: 18px;
                padding: 0 5px;
            }
            &:hover {
                .minicart-wrapper {
                    display: block;
                }
            }
        }
        &.checkout-button {
            border-radius: 0 3px 3px 0;
        }
    }

    .minicart-wrapper {
        display: none;
        margin: 10px 0;
        left: 20px;
        right: 20px;
        position: absolute;
        top: 25px;
        background: #fff;
        border-radius: 5px;
        padding: 15px;
    }
    @include clearfix();
}
