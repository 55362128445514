.checkout-cart-index {
    .cart-table {
        .cart-items {
            margin: 10px 0;
            position: relative;
            width: 100%;
            border: 1px solid #ddd;
            padding: 10px;
            background-color: #eee;
            .product-cart-image {
                background: #fff;
                padding: 10px;
                max-width: 150px;
                img {
                    width: 100%;
                }
            }
            .cart-item-details {
                flex-direction: column;
                flex-wrap: wrap;
                padding-bottom: 10px;
                h2 {
                    font-size: 16px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                .product-cart-extra {
                    margin: 0;
                }
            }
            .product-cart-remove {
                position: absolute;
                top: 15px;
                right: 25px;
                cursor: pointer;
            }
            .product-cart-info {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .cart-item-details {
                    flex: 1 1 auto;
                }
                .cart-item-info {
                    border-top: 1px solid $border-color;
                    align-self: flex-end;
                    padding: 10px 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .product-cart-actions {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        input {
                            display: inline-block;
                            margin: 10px;
                        }
                    }
                    .product-cart-price {
                        text-align: right;
                    }
                    .product-cart-total {
                        text-align: right;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .cart-totals-wrapper {
        //padding: 0 20px;

        @media (min-width: 640px) {
            padding: 0;
        }

        > div {
            &:first-child {
                padding: 0;

                @media (min-width: 640px) {
                    padding: 8px 10px 30px 0;
                }

                input, textarea {
                    width: 100%;
                }
            }

            &:last-child {
                padding: 20px 0 20px 10px;
            }
        }

        hr {
            width: 100%;
            margin: 20px 0;
        }

        .cart-totals {
            border-bottom: 1px solid $border-color;
            padding-bottom: 20px;

            table {
                width: 100%;
                tfoot {
                    height: 50px;
                    font-size: 18px;
                }
                td {

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        .to-checkout {
            height: 100px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 25px;
            ul {
                width: 100%;
            }
            button {
                width: 100%;
            }
            .btn-checkout {
                background-color: #669966;
                font-size: 18px;
                padding: 2px 35px;
                line-height: 28px;
            }
            .btn-continue {
                background: #666;
                font-size: 16px;
                padding: 2px 25px;
                line-height: 28px;
                margin: 0;
            }
        }
    }
    .cart-empty {
        text-align: center;
        padding: 30px 0 0;
        width: 100%;
    }
    @media (min-width: 640px) {
        .cart-table {
            .cart-items {
                .cart-item-details {
                    h2 {
                        font-size: 18px;
                    }
                }
                .product-cart-info {
                    padding-left: 25px;
                    .cart-item-info {
                        height: 60px;
                        border-top: 1px solid $border-color;
                        align-self: flex-end;
                        padding: 10px 0;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .product-cart-actions {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            input {
                                display: inline-block;
                                margin: 10px;
                            }
                        }
                        .product-cart-total {
                            text-align: right;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .cart-empty {
            text-align: left;
        }

        .cart-totals-wrapper {
            .to-checkout {
                padding-top: 0;
                ul {
                    width: auto;
                }
                button {
                    margin-left: 20px;
                    width: auto;
                }
            }
        }
    }
}