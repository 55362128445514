.catalog-category-view {
    .page-title, .category-description {
        display: none;
    }
    .category-products {
        padding: 20px 0;
        h1 {
            margin: 10px 0 20px 0;
        }
        .toolbar {
            justify-content: flex-end;
            div {
                margin: 0 0 0 5px;
            }
        }
        .products {

            @media (min-width: 640px) {
                padding: 0 0 0 20px;
            }

            .category-description {
                display: block;
            }
        }
    }
}

.category-sidebar {
    padding: 0 15px 15px 0;
    //border-right: 1px solid $border-color;

    h3 {
        margin-top: -5px;
    }

    ul {
        list-style: none;
        padding: 0;
        li {
            padding: 8px 0;
            border-top: 1px solid $border-color;
            a {
                padding: 3px 0 3px 25px;
                display: block;
                line-height: 18px;
            }
        }
        span {
            width: 20px;
            position: relative;
        }
        i {
            position: absolute;
            color: $highlight;
            top: 4px;
            &.fa-minus {
                display: none;
            }
        }
        &.top_cat {
            li {
                a {
                    font-weight: 700;
                }
            }

            > li {
                &:last-child {
                    border-bottom: 1px solid $border-color;
                }
            }
        }
        &.sub_cat {
            margin-top: 8px;
            display: none;
            li {
                padding-left: 15px;

                &:last-child {
                    margin-bottom: -8px;
                }

                a {
                    font-weight: 400;

                }
            }
        }
        &.sub_sub_cat {
            margin-top: 8px;
            display: none;
            li {
                padding-left: 30px;

                &:last-child {
                    margin-bottom: -8px;
                }

                a {
                    font-weight: 400;
                }
            }
        }

        .qty {
            font-weight: 400;
            padding-left: 3px;
        }
    }
}