* {
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background-color: #f2f2f2;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    border-top: 3px solid $highlight;
}

.no-overflow {
    height: 100vh;
    overflow: hidden;
}

.mobile-indicator {
    display: none;

    @media (max-width: 767px) {
        display: block;
    }
}

a {
    color: $text-color;
    text-decoration: none;

    &:visited {
        color: $text-color;
    }
    &:hover {
        color: $highlight;
    }
    &:active {
        color: $text-color;
    }
    &:focus {
        outline: none;
    }
}

h1, h2, h3 {
    color: $highlight;
}

h2 {
    padding-bottom: 4px;
}

hr {
    height: 1px;
    color: #ddd;
    background-color: #ddd;
    border: none;
}

.no-overflow {
    height: 100vh;
    overflow: hidden;
}

.clear {
    clear: both;
}

.breadcrumbs {
    font-size: 10px;
    border-bottom: 1px solid #ddd;
    padding: 0 5px;
    line-height: 14px;

    @media (min-width: 640px) {
        font-size: 12px;
        line-height: 18px;
    }

    ul {
        padding: 0;
        margin: 15px 0 15px 0;
        @media (min-width: 640px) {
            margin: 5px 0 15px 0;
        }

        li {
            display: inline-block;
        }
    }
}

.page-title {
    width: 100%;
}

ul {
    list-style: none;
    padding: 0;
}

hr {
    margin: 10px 0;
}

.main-container {
    padding-bottom: 60px;
}

.left {
    padding: 0;

    @media (min-width: 640px) {
        padding: 15px 15px 15px 5px;
        border-right: 1px solid #ccc;
    }
}

.right {
    padding: 0;
    margin: 0;

    @media (min-width: 640px) {
        padding: 15px 0 15px 15px;
    }
}

.validation-error {
    color: #991C22;
    line-height: 30px;
}

#map {
    height: 250px;
    width: 99%;
    margin: 20px 0;
    border: 1px solid #fff;
}

a[href^="http://maps.google.com/maps"] {
    display: none !important
}

.gm-style-cc {
    display: none
}

#cd-zoom-in, #cd-zoom-out {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-left: 10px;
    background-color: rgba(153, 28, 34, 1);
    background-repeat: no-repeat;
    background-size: 32px 64px;
    background-image: url(../images/icon-zoom.svg);
}

#cd-zoom-in:hover, #cd-zoom-out:hover {
    background-color: rgba(153, 28, 34, .9)
}

#cd-zoom-in {
    background-position: 50% 0;
    margin-top: 0;
    margin-bottom: 2px
}

#cd-zoom-out {
    background-position: 50% -32px
}

.std {
    width: 100%;
}

.main-container {
    max-width: 100%;
    padding: 0 15px;
}

.notice-cookie {
    background: #ccc;
    position: relative;
    display: none;
    width: 100%;
    .notice-inner {
        display: flex;
        padding: 5px 30px;
        align-items: center;
        width: 100%;
        .notice-text {
            text-align: center;
            width: 100%;
        }
        .actions {
            width: 100%;
            text-align: center;
            button {
                float: none;
                margin-bottom: 20px;
            }
        }
    }
    .close {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
    a {
        font-style: italic;
        text-decoration: underline;
    }
}

@media (min-width: 640px) {
    .notice-cookie {
        .notice-inner {
            width: 60%;
            margin: 0 auto;
            .notice-text {
                width: 80%;
            }
            .actions {
                width: 20%;
                button {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.sorter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.pager {
    width: 100%;

    a {
        text-decoration: underline;
        cursor: pointer;
    }

    .pages {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        ol {
            list-style: none;
            padding: 0;
            display: flex;
            margin: 0;

            li {
                padding: 0 3px;

                &.current {
                    font-weight: 700;
                }
            }
        }
    }
}

.messages {
    width: 100%;

    .success-msg {
        border: 1px solid #669966;
        padding: 5px 10px;
        color: #669966;
        background: #c0d8c0;
    }
}
