.product-view {
    .container {
        margin: 20px 0;
        & > div {
            &:first-child {
                padding-right: 20px;
            }
            & > div {
                padding: 20px;
                background: #fff;
                position: relative;
                border: 1px solid #ddd;
                margin-bottom: 20px;
                overflow: hidden;
            }
            .product-img {
                .product-img-box {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .product-info {
                h1 {
                    margin: 0;
                    font-size: 18px;
                    line-height: 28px;

                    @media (min-width: 640px) {
                        font-size: 24px;
                    }
                }
                .brand {
                    margin-top: 0;
                    //font-weight: 700;
                    font-size: 14px;
                }
                .add-to-cart {
                    @include flexbox();
                    align-items: flex-end;
                    padding: 10px 0;
                    .add-to-cart-buttons {
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }
                }
                .sharing-links {
                    i {
                        font-size: 18px;
                        padding-right: 10px;
                        line-height: 20px;
                    }
                    h3 {
                        margin-bottom: 5px;
                    }
                }

                .last-updated {
                    font-size: 12px;
                    color: #999;
                    font-style: italic;
                }
            }
        }
        .more-views {
            ul {
                list-style: none;
                padding: 0;
                li {
                    margin: 5px;
                    border: 1px solid $border-color;
                    .thumb-link {
                        padding: 5px 5px 0 5px;
                        width: 100%;
                        height: 100%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}