.products-grid {
    @media (min-width: 640px) {
        padding: 20px 0;
    }
    list-style: none;
    .item-wrapper {
        padding: 10px 0;

        @media (min-width: 640px) {
            padding: 10px;
        }
        .item {
            background-color: #fff;
            padding: 15px;
            border: 1px solid $border-color;
            position: relative;
            overflow: hidden;
            .product-header {
                height: 72px;
                line-height: 18px;
                .product-name {
                    color: $highlight;
                    font-size: 16px;
                    margin-bottom: 0;
                    padding: 0;
                    a {
                        color: $highlight;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .brand {
                    margin-top: 0;
                    font-size: 14px;
                    color: #a2a2a2;
                }
            }
            .price-box {
                height: 52px;
                .price {
                    color: $highlight;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 700;
                }
                .from-price {
                    margin-top: 36px;
                    height: 17px;
                    font-size: 12px;
                    color: #333;
                    font-weight: 700;
                    text-decoration: line-through;
                    margin-bottom: 0;
                    line-height: 17px;
                }

                .monthly-price {
                    font-size: 12px;
                    font-weight: 400;
                    margin-top: -6px;
                }
            }
            .actions {
                display: block;
                a, button {
                    display: block;
                    width: 100%;
                    margin-top: 8px;
                }
            }
        }
    }
}

.product-image {
    width: 100%;
    @include aspect-ratio(1, 1);
    border: 1px solid $border-color;
    .inner {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
        margin: 10px;

        @media (min-width: 640px) {
            margin: 20px;
        }
    }

    i {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 18px;
        color: #333;
    }
}

.product-overlay {
    background-color: rgba(153,28,34,0.9);
    right: -42px;
    -ms-transform: rotate(45deg);
    -moz-transform: translate3d(0,0,0) rotate(45deg);
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
    -o-transform: translate3d(0,0,0) rotate(45deg);
    -ms-transform: translate3d(0,0,0) rotate(45deg);
    transform: translate3d(0,0,0) rotate(45deg);
    top: 31px;
    position: absolute;
    width: 190px;
    z-index: 10;
    text-align: center;
    padding: 8px 0;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    span {
        display: inline-block;
        color: #fff;
        font-weight: 600;
        white-space: nowrap;
        max-width: 85%;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}

.product-view {
    width: 100%;

    .product-info {
        @media (min-width: 640px) {
            margin-left: 20px;
        }
            .product-name {
                color: $highlight;
                font-size: 16px;
                margin-bottom: 0;
                padding: 0;
                a {
                    color: $highlight;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .brand {
                margin-top: 0;
                font-size: 14px;
                color: #a2a2a2;
            }
        .price-box {
            .price {
                color: $highlight;
                font-size: 18px;
                line-height: 18px;
                font-weight: 700;
            }
            .from-price {
                margin-top: 0;
                height: 17px;
                font-size: 12px;
                color: #333;
                font-weight: 700;
                text-decoration: line-through;
                margin-bottom: 5px;
                line-height: 17px;
            }

            .monthly-price {
                font-size: 12px;
                font-weight: 400;
                margin-top: -2px;
            }
        }
    }
}


