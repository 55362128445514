.container {
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  flex-wrap: wrap;;
  width: 100%;
  max-width: 1240px;
}

[class*="span"] { padding: 0; }
[class*="-ss12"] { width: 100%; }
[class*="-ss11"] { width: 91.6666667%; }
[class*="-ss10"] { width: 83.333333334%; }
[class*="-s9"] { width: 75%; }
[class*="-s8"] { width: 66.666666667%; }
[class*="-s7"] { width: 58.3333333%; }
[class*="-s6"] { width: 50%; }
[class*="-s5"] { width: 41.6666667%; }
[class*="-s4"] { width: 33.333333333%; }
[class*="-s3"] { width: 25%; }
[class*="-s2"] { width: 16.666666667%; }
[class*="-s1"] { width: 8.33333333%; }
[class*="-s0"] { width: 0; }

@media screen and (min-width: 640px) {
  .container {

  }
  [class*="-mm12"] { width: 100%; }
  [class*="-mm11"] { width: 91.6666667%; }
  [class*="-mm10"] { width: 83.333333334%; }
  [class*="-m9"] { width: 75%; }
  [class*="-m8"] { width: 66.666666667%; }
  [class*="-m7"] { width: 58.3333333%; }
  [class*="-m6"] { width: 50%; }
  [class*="-m5"] { width: 41.6666667%; }
  [class*="-m4"] { width: 33.333333333%; }
  [class*="-m3"] { width: 25%; }
  [class*="-m2"] { width: 16.666666667%; }
  [class*="-m1"] { width: 8.33333333%; }
  [class*="-m0"] { width: 0; }
}

@media screen and (min-width: 1000px) {
  .container {

  }
  [class*="-ll12"] { width: 100%; }
  [class*="-ll11"] { width: 91.6666667%; }
  [class*="-ll10"] { width: 83.333333334%; }
  [class*="-l9"] { width: 75%; }
  [class*="-l8"] { width: 66.666666667%; }
  [class*="-l7"] { width: 58.3333333%; }
  [class*="-l6"] { width: 50%; }
  [class*="-l5"] { width: 41.6666667%; }
  [class*="-l4"] { width: 33.333333333%; }
  [class*="-l3"] { width: 25%; }
  [class*="-l2"] { width: 16.666666667%; }
  [class*="-l1"] { width: 8.33333333%; }
  [class*="-l0"] { width: 0; }
}