input,
textarea {
  -webkit-appearance: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  display: block;
  padding: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: 2px solid #fff;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  &:focus {
    outline: none;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  color: #333;
  line-height: 18px;
}

.button:not(.search-button), button:not(.search-button) {
  height: 34px;
  line-height: 30px;
  padding: 2px 20px;
  color: #fff;
  background-color: $highlight;
  float: right;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  @include transition(all, 0.5s, 0s);
  &:hover {
    background-color: #333;
    color: #fff;
  }
  &:visited {
    color: #fff;
  }
  &:active {
    color: #fff;
  }
  &:focus {
    outline: none;
  }
  &.add {
    margin: 5px 0 0 0;
    width: 100%;
    text-align: center;
  }
  &.more {
    float: none;
    display: block;
    background: #666;
    width: 100%;
    margin: 10px 0 0 0;
    text-align: center;
    &:hover {
      opacity: 0.8;
    }
  }
}

select {
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 10px 35px 10px 10px;
  font-size: 14px;
  border: 0 solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../images/icon_down.svg) right center no-repeat #eee;
  background-size: 30px;
  color: #333;
  background-color: #fff;
  &:focus {
    outline: 0;
  }
}

.option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select:hover {
  background-color: #eee;
}

.contact-form {
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    max-width: 375px;
  }
  textarea {
    height: 200px;
    width: 100%;
  }
}