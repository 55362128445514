$main-color: #fff;
$text-color: #333;
$highlight: #991C22;
//$highlight: #cc3333;
$border-color: #ddd;

@mixin transition($property, $time, $delay) {
    -webkit-transition: $property $time $delay;
    transition: $property $time $delay;
}
@mixin rotate($deg){
    -ms-transform: rotate($deg);
    -webkit-transform: rotate3d(0,0,1,$deg);
    transform: rotate3d(0,0,1,$deg);
}
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: " ";
        padding-top: ($height / $width) * 100%;
    }
    > .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}


@mixin clearfix() {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}