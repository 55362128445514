header {
    flex-direction: row;
    .page-header-container {
        width: 100%;
        position: relative;
        .logo {
            padding: 15px 20px;
            display: inline-block;
            img {
                max-width: 250px;
                height: auto;
                &.small {
                    max-width: 150px;
                    height: auto;
                }
                &.large {
                    display: none;
                }
            }
        }

        .header {
            background: #fff;
            position: relative;
            padding-bottom: 3px;

            @media (min-width: 640px) {
                background: none;
            }
        }

        .welcome-msg {
            display: none;
        }
        #header-search {
            position: absolute;
            top: 7px;
            right: 9px;
            left: 9px;

            .search-button {
                right: 10px;
                position: absolute;
                z-index: 2;
                margin-top: 10px;
                border: 0;
                background: #fff;
                font-size: 16px;
                width: 30px;
                i {
                    color: #666;
                }
                &:focus {
                    outline: none;
                }
            }
            .search-field {
                width: 80px;
                height: 40px;
                position: absolute;
                z-index: 1;
                right: 0;
                border: 2px solid #fff;
                padding-left: 10px;
                color: #666;
                @include transition(width, 0.6s, 0s);
                &:hover {
                    width: 100%;
                }
                &:focus {
                    width: 100%;
                    //-webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.4);
                    //-moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.4);
                    //box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.4);
                }
            }

            @media (min-width: 640px) {
                position: relative;
                right: auto;
                left: auto;

                .search-field {
                    width: 180px;
                    &:hover {
                        width: 220px;
                    }
                    &:focus {
                        width: 220px;
                    }
                }
            }
        }
    }
    nav {
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: $main-color;
        opacity: 0;
        pointer-events: none;
        text-align: center;
        @include transition(opacity, 0.5s, 0s);
        ul {
            width: 100%;
            margin: 0;
            padding: 60px 30px 0 30px;
            flex-direction: column;
            -webkit-flex-direction: column;
            list-style: none;
            display: flex;
        }
        li {
            text-align: center;
            border-bottom: 1px solid $border-color;
        }
        a {
            width: 100%;
            line-height: 60px;
            color: #333;
            text-decoration: none;
            font-size: 14px;
            &:visited {
                color: #333;
            }
            &:active {
                color: #333;
            }
        }
        &.visible {
            opacity: 1;
            pointer-events: all;
        }
        .total-products {
            margin: 0 auto;
            line-height: 50px;
        }
    }
    .menu-button {
        right: 10px;
        top: 10px;
        z-index: 1001;
        position: absolute;
        display: block;
        cursor: pointer;
        width: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 3px;
        -webkit-border-radius: 25px;
        background-color: #991C22;
        span {
            background-color: $main-color;
            position: absolute;
            width: 30px;
            height: 3px;
        }
        .top {
            top: 16px;
            margin-top: 0;
            transition: transform 0.3s 0s, top 0.3s 0s;
            -webkit-transition: transform 0.3s 0s, top 0.3s 0s;
        }
        .middle {
            top: 24px;
            transition: opacity, 0.3s, 0.2s;
            -webkit-transition: opacity, 0.3s, 0.2s;
        }
        .bottom {
            top: 32px;
            margin-top: 0;
            transition: transform 0.3s 0s, top 0.3s 0s;
            -webkit-transition: transform 0.3s 0s, top 0.3s 0s;
        }
    }

    .on {
        position: fixed;
        .top {
            top: 24px;
            @include rotate(45deg);
        }
        .middle {
            opacity: 0;
        }
        .bottom {
            @include rotate(-45deg);
            top: 24px;
        }
    }
    @media (min-width: 640px) {
        .page-header-container {
            .welcome-msg {
                display: block;
                margin: 60px 0;
                font-family: 'Yellowtail', serif;
                font-size: 24px;
                color: $highlight;
                padding-left: 30px;
            }
        }
        .menu-button {
            display: none;
        }
        nav {
            height: auto;
            margin-bottom: 10px !important;
            pointer-events: all;
            opacity: 1;
            top: 0;
            left: 0;
            right: 0;
            position: relative;
            background-color: #fff;
            border: 1px solid $border-color;
            ul {
                padding: 0;
                flex-direction: row;
                -webkit-flex-direction: row;
                display: flex;
            }
            li {
                display: inline;
                text-align: center;
                border-right: 1px solid $border-color;
                border-bottom: 3px solid transparent;
                @include transition(all, 0.6s, 0s);
                &:hover, &.active {
                    border-bottom: 3px solid $highlight;
                }
            }
            a {
                border-bottom: none;
                line-height: 50px;
                width: auto;
                padding: 3px 20px 0 20px;
                display: inline;
                text-align: center;
                color: #333;
                text-decoration: none;
                font-size: 14px;
            }
            .total-products {
                display: none;
            }
        }
        .page-header-container {
            .logo {
                img {
                    &.small {
                        display: none;
                    }
                    &.large {
                        display: inline-block;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        nav {
            .total-products {
                display: inline-block;
                position: absolute;
                right: 20px;

            }
        }
    }
    @media (min-width: 1200px) {
        .page-header-container {
            .logo {
                img {
                    &.small {
                        display: none;
                    }
                    &.large {
                        display: inline-block;
                    }
                }
            }
        }
    }
}