.cms-home {
    .start-button {
        background: rgba(181, 36, 43, 1);
        background: -moz-linear-gradient(top, rgba(181, 36, 43, 1) 0%, rgba(199, 50, 57, 1) 50%, rgba(186, 37, 45, 1) 51%, rgba(117, 14, 19, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181, 36, 43, 1)), color-stop(50%, rgba(199, 50, 57, 1)), color-stop(51%, rgba(186, 37, 45, 1)), color-stop(100%, rgba(117, 14, 19, 1)));
        background: -webkit-linear-gradient(top, rgba(181, 36, 43, 1) 0%, rgba(199, 50, 57, 1) 50%, rgba(186, 37, 45, 1) 51%, rgba(117, 14, 19, 1) 100%);
        background: -o-linear-gradient(top, rgba(181, 36, 43, 1) 0%, rgba(199, 50, 57, 1) 50%, rgba(186, 37, 45, 1) 51%, rgba(117, 14, 19, 1) 100%);
        background: -ms-linear-gradient(top, rgba(181, 36, 43, 1) 0%, rgba(199, 50, 57, 1) 50%, rgba(186, 37, 45, 1) 51%, rgba(117, 14, 19, 1) 100%);
        background: linear-gradient(to bottom, rgba(181, 36, 43, 1) 0%, rgba(199, 50, 57, 1) 50%, rgba(186, 37, 45, 1) 51%, rgba(117, 14, 19, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b5242b', endColorstr='#750e13', GradientType=0);
        width: 100%;
        border-radius: 3px;
        -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.36);
        -moz-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.36);
        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.36);
        margin-bottom: 20px;
        &:hover {
            background: rgba(128, 25, 30, 1);
            background: -moz-linear-gradient(top, rgba(128, 25, 30, 1) 0%, rgba(139, 35, 40, 1) 50%, rgba(128, 25, 30, 1) 51%, rgba(82, 10, 13, 1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128, 25, 30, 1)), color-stop(50%, rgba(139, 35, 40, 1)), color-stop(51%, rgba(128, 25, 30, 1)), color-stop(100%, rgba(82, 10, 13, 1)));
            background: -webkit-linear-gradient(top, rgba(128, 25, 30, 1) 0%, rgba(139, 35, 40, 1) 50%, rgba(128, 25, 30, 1) 51%, rgba(82, 10, 13, 1) 100%);
            background: -o-linear-gradient(top, rgba(128, 25, 30, 1) 0%, rgba(139, 35, 40, 1) 50%, rgba(128, 25, 30, 1) 51%, rgba(82, 10, 13, 1) 100%);
            background: -ms-linear-gradient(top, rgba(128, 25, 30, 1) 0%, rgba(139, 35, 40, 1) 50%, rgba(128, 25, 30, 1) 51%, rgba(82, 10, 13, 1) 100%);
            background: linear-gradient(to bottom, rgba(128, 25, 30, 1) 0%, rgba(139, 35, 40, 1) 50%, rgba(128, 25, 30, 1) 51%, rgba(82, 10, 13, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80191e', endColorstr='#520a0d', GradientType=0);
        }
        a {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            line-height: 50px;
            height: 50px;
            width: 100%;
            padding: 0 20px;
        }
    }
    .products {
        padding: 0;
        .category-description {
            display: block;
        }
        .jumbotron {
            width: 100%;
            height: 280px;
            background: url(../images/jumbotron.jpg) no-repeat center center;
            background-size: cover;
            text-align: center;
            .stroke {
                width: 90%;
                max-width: 550px;
            }
            .desc {
                color: #fff;
                text-align: center;
                margin: 0;
                padding: 10px 35px;
                font-style: italic;
                font-weight: 100;
                font-size: 14px;
                text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
                &:first-child {
                    padding-top: 50px;
                }
                &:last-child {
                    padding-top: 0;
                }
            }
        }
        .shadow {
            height: 20px;
            width: 100%;
            background: url(../images/product_shadow.png) no-repeat;
            background-size: contain;
        }
    }
    @media (min-width: 640px) {
        .startpage-sidebar {
            padding: 0 15px 15px 0;
            border-right: 1px solid #ddd;
            h3 {
                margin-top: 0;
            }
        }
        .products {
            padding: 0 0 0 20px;
            .jumbotron {
                width: 100%;
                height: 300px;
                background-size: 100%;
                text-align: right;
                padding-right: 35px;
                .desc {
                    font-size: 14px;
                    float: right;
                    &:first-child {
                        padding-top: 100px;
                    }
                    &:last-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}