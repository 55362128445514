.partners {
    padding: 20px;
    margin: 30px auto 0 auto;
    background-color: #ccc;
    align-items: center;

    h3 {
        text-shadow: 0 1px 0 #DDD;
        color: #606060;
        display: block;
        text-align: center;
        width: 100%;

        @media (min-width: 640px) {
            text-align: right;
        }
    }
    .partner-logos {
        border-bottom: 1px solid #999;
        padding-bottom: 20px;
        .logos {
            text-align: center;
            img {
                width: 80%;
                height: auto;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                &:hover {
                    filter: none;
                    -webkit-filter: grayscale(0%);
                }
            }
        }
    }
}

.footer-container {
    margin-top: -2px;

    img {
        max-width: 225px;
        margin-top: 10px;
        padding: 0 10px;
    }
    .footer {
        background-color: #ccc;
        font-size: 12px;
        text-shadow: 0 1px 0 #DDD;
        color: #606060;
        text-align: center;
        //border-radius: 5px;
        align-items: center;

        .bisnode {
            text-align: center;

            @media (min-width: 640px) {
                text-align: right;
            }
        }

        .block-subscribe {
            order: 2;
            .form-subscribe-header {
                margin: 10px 0;
            }
            input {
                float: left;
                width: 75%;
                border-radius: 3px 0 0 3px;
            }
            button {
                float: left;
                width: 25%;
                height: 39px;
                border-radius: 0 3px 3px 0;
            }
        }
        .block-title {
            font-size: 18px;
        }
        > div {
            padding: 20px;

        }
    }
    .copyright {
        display: block;
        text-align: center;
        padding: 25px 30px;
        font-size: 12px;
        background: #333;
        color: #ccc;
    }
}

@media (min-width: 640px) {
    .partners {
        border-radius: 5px 5px 0 0;
    }
    .footer-container {
        .footer {
            border-radius: 0 0 5px 5px;
            text-align: left;
        }
        .copyright {
            background: transparent;
            color: $text-color;
        }
    }
}
